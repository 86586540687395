@import '../../styles/customMediaQueries.css';

.root {
  & p,
  & li {
    /* h4 marketplace default */
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    /* marketplaceBodyFontStyles marketplace default */
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;

    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;

      margin-top: 0;
      margin-bottom: 54px;
    }
  }

  & ul {
    padding-left: 30px;
    list-style: disc outside none;
  }
}
